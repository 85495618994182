import store from '@/plugins/store';

function showDiaserDefect (notification) {
  store.commit('defect/showDefect', notification.activity.payload.defectId);
}

const EVENT_CALLBACKS = {
  DIASER_EVENT_DEFECT_CREATED: showDiaserDefect,
  DIASER_MINOR_DEFECT_CREATED: showDiaserDefect,
  DIASER_MAJOR_DEFECT_CREATED: showDiaserDefect,
  DIASER_EVENT_DEFECT_UPDATED: showDiaserDefect,
  DIASER_MINOR_DEFECT_UPDATED: showDiaserDefect,
  DIASER_MAJOR_DEFECT_UPDATED: showDiaserDefect,
};

export const goToNotificationTarget = (notification) => {
  if (notification.activity?.__typename === 'Event' && EVENT_CALLBACKS[notification.activity.type]) {
    EVENT_CALLBACKS[notification.activity.type](notification);

    return true;
  } else {
    return null;
  }
};

export const iconByType = (type) => ({
  CROSSROAD_USER_STATE_CHANGED: 'mdi-progress-wrench',
  CROSSROAD_STATION_WATCHER_STATE_CHANGED: 'mdi-progress-wrench',
  CROSSROAD_STATION_STATE_CHANGED: 'mdi-progress-wrench',
  CROSSROAD_OUT_OF_CONTROL: 'mdi-steering-off',
  CROSSROAD_IN_CONTROL: 'mdi-steering',
  STATION_DEFECT_CREATED: 'mdi-progress-wrench',
  STATION_DEFECT_UPDATED: 'mdi-progress-wrench',
  STATION_SYNCHRONIZED: 'mdi-sync',
  STATION_OUT_OF_SYNC: 'mdi-sync-alert',
  USER_AUTHENTICATED: 'mdi-account-alert',
  WAMP_CONNECTION_FAILED: 'mdi-access-point-network-off',
  WAMP_CONNECTION_CLOSED: 'mdi-access-point-network-off',
  WAMP_CONNECTION_SUCCESS: 'mdi-access-point-network',
  WAMP_AUTHENTICATION_FAILED: 'mdi-lan-disconnect',
  WAMP_AUTHENTICATION_SUCCESS: 'mdi-lan-connect',
  WAMP_AUTHORIZATION_FAILED: 'mdi-shield-off',
  DIASER_EVENT_DEFECT_CREATED: 'mdi-information',
  DIASER_MINOR_DEFECT_CREATED: 'mdi-alert',
  DIASER_MAJOR_DEFECT_CREATED: 'mdi-alert-circle',
  DIASER_EVENT_DEFECT_UPDATED: 'mdi-check',
  DIASER_MINOR_DEFECT_UPDATED: 'mdi-check',
  DIASER_MAJOR_DEFECT_UPDATED: 'mdi-check',
}[type] ?? 'mdi-progress-wrench');
