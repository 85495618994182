<template>
  <div>
    <v-menu
      v-model="show"
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      content-class="mt-2"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            :color="notSeenNotificationCount > 0 ? 'red' : null"
            :bordered="notSeenNotificationCount > 0"
            overlap
          >
            <template
              v-if="notSeenNotificationCount > 0"
              v-slot:badge
            >
              <span>{{ notSeenNotificationCount }}</span>
            </template>

            <v-icon
              size="22"
              color="white"
            >
              mdi-bell
            </v-icon>
          </v-badge>
        </v-btn>
      </template>

      <perfect-scrollbar
        ref="scroll"
        class="no-scroll-x fill-height"
        style="max-height: 500px;"
      >
        <v-card class="ma-0 pa-0">
          <v-list
            nav
            width="350"
            class="fill-height"
          >
            <v-list-item
              v-if="loading"
              class="d-flex justify-center"
            >
              <o-loader
                :width="200"
                :height="200"
              />
            </v-list-item>

            <template v-else-if="notifications && notifications.edges.length">
              <v-list-item
                class="my-0 py-0"
                @click.stop
              >
                <v-list-item-content class="my-0 py-0">
                  <v-list-item-title class="d-flex justify-space-between">
                    <span class="py-2">
                      {{ $t('messages.views.admin.components.core.notifications.title') }}
                    </span>
                    <v-menu
                      bottom
                      left
                      offset-y
                      origin="top right"
                      transition="scale-transition"
                      content-class="mt-2"
                    >
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                      </template>

                      <v-list dense>
                        <v-list-item @click="markAllNotificationsAsSeen">
                          <v-list-item-title>
                            {{ $t('messages.views.admin.components.core.notifications.markAllNotificationsAsSeen') }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="notifications.pageInfo.hasPreviousPage || pagination.after !== null"
                dense
                class="pa-0 ma-0"
              >
                <v-list-item-content class="pa-0 ma-0">
                  <div class="d-flex justify-center">
                    <v-btn
                      block
                      @click.stop="previous"
                    >
                      <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>

              <notification
                v-for="notification in notifications.edges.map(edge => edge.node)"
                :key="`item-${notification.id}`"
                :notification="notification"
                @unseen="() => markNotificationAsNotSeen(notification)"
                @click="() => onNotificationClick(notification)"
              />

              <v-list-item
                v-if="notifications.pageInfo.hasNextPage || pagination.before !== null"
                dense
                class="pa-0 ma-0"
              >
                <v-list-item-content class="pa-0 ma-0">
                  <div class="d-flex justify-center">
                    <v-btn
                      block
                      @click.stop="next"
                    >
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item v-else>
              <v-list-item-content class="font-italic text-caption">
                {{ $t('messages.views.admin.components.core.notifications.noNotification') }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </perfect-scrollbar>
    </v-menu>
    <o-activity-details-dialog v-model="selectedActivity" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import webSocketClient from '@mixin/web-socket-client';
  import WebSocketTopic from '@api/web-socket-topic';
  import { goToNotificationTarget } from '@utils/notification';

  const DEFAULT_ITEMS_PER_PAGE = 10;

  export default {
    components: {
      notification: () => import('@/views/admin/components/core/Notification'),
    },
    mixins: [webSocketClient],
    apollo: {
      notifications: {
        query: require('@gql/views/admin/components/core/notifications/getData.gql'),
        variables: function () {
          return {
            first: this.pagination.first,
            last: this.pagination.last,
            before: this.pagination.before,
            after: this.pagination.after,
          };
        },
        client: 'activity-thread',
        fetchPolicy: 'no-cache',
        result (res) {
          this.loading = res.loading;
        },
        update: function (data) {
          this.notificationCount = data.notifications.totalCount;
          this.notSeenNotificationCount = data.notSeenNotifications.totalCount;

          return data.notifications;
        },
      },
    },

    data: () => ({
      show: false,
      loading: true,
      pagination: {
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        first: DEFAULT_ITEMS_PER_PAGE,
        last: null,
        before: null,
        after: null,
      },
      notificationCount: 0,
      notSeenNotificationCount: 0,
      components: {},
      selectedActivity: null,
    }),

    computed: {
      ...mapState('auth', ['user']),
    },

    watch: {
      pagination: {
        handler () {
          this.loading = true;
          this.$refs.scroll.$el.scrollTop = 0;
        },
        deep: true,
      },

      show (newValue) {
        if (!newValue) {
          this.resetPagination();
        }
      },

      $route: {
        immediate: true,
        handler (to) {
          if (to.query && to.query.notification) {
            this.$apollo.query({
              query: require('@gql/queries/notification/getNotification.gql'),
              client: 'activity-thread',
              variables: {
                id: to.query.notification,
              },
            })
              .then((result) => {
                if (result.data.getNotification !== null) {
                  this.showNotification(result.data.getNotification);
                }
              })
            ;
          }
        },
      },
    },

    mounted () {
      this.webSocketSubscribe(WebSocketTopic.getTopic(WebSocketTopic.USER_NOTIFICATION_CREATED, { userId: this.user.id }), this.onNotificationCreated);
    },

    methods: {
      previous () {
        this.pagination.first = null;
        this.pagination.after = null;
        this.pagination.last = this.pagination.itemsPerPage;
        this.pagination.before = this.notifications.edges[0].cursor;
      },

      next () {
        this.pagination.first = this.pagination.itemsPerPage;
        this.pagination.after = this.notifications.edges[this.notifications.edges.length - 1].cursor;
        this.pagination.last = null;
        this.pagination.before = null;
      },

      resetPagination () {
        this.pagination.first = this.pagination.itemsPerPage;
        this.pagination.after = null;
        this.pagination.last = null;
        this.pagination.before = null;
      },

      onNotificationCreated (notification) {
        this.$apollo.queries.notifications.refresh();
      },

      markNotificationAsSeen (notification) {
        notification.seen = true;
        this.notSeenNotificationCount--;
      },

      markNotificationAsNotSeen (notification) {
        notification.seen = false;
        this.notSeenNotificationCount++;
      },

      markAllNotificationsAsSeen () {
        if (this.notifications.edges.length === 0) {
          return;
        }

        const mostRecentNotificationDate = this.notifications.edges.reduce((latest, edge) => {
          const notificationDate = new Date(edge.node.createdAt);
          return latest > notificationDate ? latest : notificationDate;
        }, new Date(this.notifications.edges[0].node.createdAt));

        this.$apollo.mutate({
          mutation: require('@gql/mutations/notification/markAllNotificationsAsSeen.gql'),
          client: 'activity-thread',
          variables: {
            input: {
              lastDate: mostRecentNotificationDate,
            },
          },
        });

        for (const edge of this.notifications.edges) {
          edge.node.seen = true;
        }

        this.notSeenNotificationCount = 0;
      },

      onNotificationClick (notification) {
        if (!notification.seen) {
          this.$apollo.mutate({
            mutation: require('@gql/mutations/notification/markNotificationAsSeen.gql'),
            client: 'activity-thread',
            variables: {
              input: {
                id: notification.id,
              },
            },
          });
        }
        this.markNotificationAsSeen(notification);
        this.showNotification(notification);
      },

      showNotification (notification) {
        if (!goToNotificationTarget(notification)) {
          this.selectedActivity = notification.activity;
        }
      },
    },
  };
</script>
